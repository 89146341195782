import React from "react";
import HomeNavbar from "../components/HomeNavbar";
import {
  Box,
  Text,
  Image,
  //   Center,
  // Show,
  // Hide
} from "@chakra-ui/react";
import DashboardImage from "../assets/winsise-image.jpg";
import { colors } from "../theme/colors";

export default function LandingPage() {
  return (
    <>
      <HomeNavbar />

      <Box paddingX={{ base: "0px", md: "30px", lg: "50px" }} paddingTop={10}>
        <Box
          display={"flex"}
          flexDir={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          paddingX={{ base: "20px", md: 0 }}
        >
          <Box>
            <Text
              fontSize={{ base: "24px", md: "30px", lg: "44px" }}
              textAlign={{ base: "center", md: "left" }}
              fontWeight={"700"}
              //fontFamily={"Open Sans"}
              width={{ base: "100%", md: "80%" }}
            >
              Discover a New Path to Personal Growth with WinWise
              <Text color={colors.primary}>Winwise</Text>
            </Text>
            <Text
              textAlign={{ base: "center", md: "left" }}
              width={{ base: "100%", md: "80%", lg: "75%" }}
              paddingBottom={{ md: "30px", lg: "50px" }}
              fontSize={{ base: 12, md: "14px", lg: "16px" }}
            >
              WinWise is a trip that will change your life. It combines ancient
              knowledge with modern technology. Dive into the depths of
              self-discovery with our all-in-one platform that combines in-depth
              numerology insights, deep dream readings, and personalized tools
              for personal growth. You will get customized help that will help
              you reach your full potential, learn more about yourself, and live
              a happier life. Get the information you need to make smart
              choices, know who you really are, and face life's problems with
              confidence with WinWise. Change the way you look at personal
              growth by joining us.
            </Text>
            <Box>
              <Text
                fontSize={{ base: "24px", md: "30px", lg: "44px" }}
                textAlign={{ base: "center", md: "left" }}
                fontWeight={"700"}
                //fontFamily={"Open Sans"}
                width={{ base: "100%", md: "80%" }}
              >
                <Text color={colors.primary}>We are coming soon!</Text>
              </Text>
            </Box>
          </Box>
          <Box width={"100%"}>
            <Image
              src={DashboardImage}
              alt={"dashboardImage"}
              height={{ base: "300px", md: "200px", lg: "400px" }}
              width={"90%"}
              borderRadius={10}
              alignSelf={"center"}
              alignItems={"center"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
