import * as React from "react";
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react";
// import { ColorModeSwitcher } from "./ColorModeSwitcher";
// import { Logo } from "./Logo";
import LandingPage from "./features/LandingPage";
import { Route, Routes } from "react-router-dom";

export const App = () => (
  <ChakraProvider theme={theme}>
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
      {/* <Text>Hell world</Text> */}
    </>
  </ChakraProvider>
);
