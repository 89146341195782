import React, { useState } from "react";
import {
  Box,
  //Text,
  Image,
  // VStack,
  // HStack,
  Show,
  Hide,
} from "@chakra-ui/react";
import Logo from "../assets/Logo.svg";
// import theme from "../theme";
import Button from "./Button";
import { colors } from "../theme/colors";
import Icons from "../assets/Icons";
import { useNavigate } from "react-router-dom";

interface NavProps {
  id: string;
  label: string;
  path?: string;
}

export default function HomeNavbar() {
  const [select, setSelect] = useState("0");
  const navigation = useNavigate();
  const nav: NavProps[] = [
    {
      id: "0",
      label: "Home",
      path: "/",
    },
    {
      id: "1",
      label: "Features",
      path: "/",
    },
    {
      id: "2",
      label: "About Us",
      path: "/",
    },
    {
      id: "3",
      label: "Contact Us",
      path: "/",
    },
  ];
  return (
    <>
      <Hide breakpoint="(max-width: 768px)">
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingX={"50px"}
          bgColor={"transparent"}
          height={"100px"}
          borderBottomWidth={1}
          shadow={"sm"}
        >
          <Box>
            <Image src={Logo} alt={"logo"} />
          </Box>
          <Box
            display={"flex"}
            flexDir={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"40%"}
            as="button"
          >
            {nav.map((items, id) => {
              return (
                <Box onClick={() => {}} key={id}>
                  <Box
                    fontFamily={"Open Sans"}
                    fontWeight={"600"}
                    fontSize={16}
                    color={
                      select === items.id ? colors.primary : colors.darkGrey
                    }
                    onClick={() => {
                      setSelect(items.id);
                    }}
                  >
                    {items.label}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Button
            onClick={() => {}}
            label={"Get Started"}
            bgColor={colors.primary}
            paddingX={10}
            height={"50px"}
            width={"194px"}
            borderRadius={"33px"}
            color="white"
            fontWeight={"700"}
          />
        </Box>
      </Hide>
      <Show breakpoint="(max-width: 768px)">
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"space-between"}
          paddingX={"30px"}
          alignItems={"center"}
          bgColor={"transparent"}
          height={"65px"}
          borderBottomWidth={1}
          shadow={"sm"}
        >
          <Icons type="hamburger" color={colors.darkGrey} />
          <Box>
            <Image src={Logo} alt={"logo"} height={10} />
          </Box>
          <Button
            onClick={() => {}}
            label={"Get Started"}
            bgColor={colors.primary}
            paddingX={"10px"}
            height={"30px"}
            borderRadius={"33px"}
            color="white"
            fontWeight={"700"}
            fontSize={12}
          />
        </Box>
      </Show>
    </>
  );
}
